import './App.css';
import { ReactP5Wrapper } from "@p5-wrapper/react";

let colors;
const fireworks = [];
let gravity;
class Particle {
  constructor(p5, x, y, hu, firework) {
    this.p5 = p5
    this.pos = this.p5.createVector(x, y);
    this.firework = firework;
    this.lifespan = 255;
    this.hu = hu;
    this.acc = this.p5.createVector(0, 0);
    if (this.firework) {
      this.vel = this.p5.createVector(0, this.p5.random(-20, -8));
      // this.vel.mult(1.5);
    } else {
      // this.vel = p5.Vector.p5.random2D();
      // this.vel.mult(p5.random(2, 10));
      const a = this.p5.random(p5.TWO_PI);
      const r = this.p5.min(this.p5.width, this.p5.height) / 40;
      const x = r * 16 * this.p5.pow(this.p5.sin(a), 3);
      const y = -r * (13 * this.p5.cos(a) - 5 * this.p5.cos(2 * a) - 2 * this.p5.cos(3 * a) - this.p5.cos(4 * a));
      this.vel = this.p5.createVector(x,y);
      this.vel.mult(this.p5.random(0.06,0.07));
    }
  }

  applyForce(force) {
    this.acc.add(force);
  }

  update() {
    if (!this.firework) {
      this.vel.mult(0.9);
      this.lifespan -= 4;
    }
    this.vel.add(this.acc);
    this.pos.add(this.vel);
    this.acc.mult(0);
  }

  done() {
    if (this.lifespan < 0) {
      return true;
    } else {
      return false;
    }
  }

  show() {

    if (!this.firework) {
      this.p5.strokeWeight(15);
      let r = this.p5.red(this.hu);
      let g = this.p5.green(this.hu);
      let b = this.p5.blue(this.hu);
      this.p5.stroke(r, g, b, this.lifespan);
    } else {
      this.p5.strokeWeight(15);
      this.p5.stroke(this.hu);
    }

    this.p5.point(this.pos.x, this.pos.y);
  }
}

class Firework {
  constructor(p5) {
    this.p5 = p5
    this.hu = p5.random(colors);
    console.log(colors)
    
    let r = p5.random(1);
    let x = p5.random(0, p5.width);
    // if (r > 0.5) {
    //   x = p5.random(3*p5.width/4, p5.width);
    // }
    this.firework = new Particle(p5, x, p5.height, this.hu, true);
    this.exploded = false;
    this.particles = [];
  }

  done() {
    if (this.exploded && this.particles.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  update() {
    if (!this.exploded) {
      this.firework.applyForce(gravity);
      this.firework.update();

      if (this.firework.vel.y >= 0) {
        this.exploded = true;
        this.explode();
      }
    }

    for (let i = this.particles.length - 1; i >= 0; i--) {
      this.particles[i].applyForce(gravity);
      this.particles[i].update();

      if (this.particles[i].done()) {
        this.particles.splice(i, 1);
      }
    }
  }

  explode() {
    for (let i = 0; i < 150; i++) {
      const p = new Particle(this.p5, this.firework.pos.x, this.firework.pos.y, this.hu, false);
      this.particles.push(p);
    }
  }

  show() {
    if (!this.exploded) {
      this.firework.show();
    }

    for (var i = 0; i < this.particles.length; i++) {
      this.particles[i].show();
    }
  }
}

function setup(p5) {
  p5.createCanvas(p5.windowWidth, p5.windowHeight);
  gravity = p5.createVector(0, 0.2);
  p5.stroke(255);
  p5.strokeWeight(4);
  p5.clear();
  colors = [
    p5.color(146, 83, 161),
    p5.color(240, 99, 164),
    p5.color(45, 197, 244),
    p5.color(252, 238, 33),
    p5.color(241, 97, 100),
    p5.color(112, 50, 126),
    p5.color(164, 41, 99),
    p5.color(11, 106, 136),
    p5.color(248, 158, 79),
    p5.color(146, 83, 161),
    p5.color(236, 1, 90)
  ];
}

function draw(p5) {
  // background(11, 106, 136, 25);
  p5.clear();

  if (p5.random(1) < 0.05) {
    fireworks.push(new Firework(p5));
  }

  for (let i = fireworks.length - 1; i >= 0; i--) {
    fireworks[i].update();
    fireworks[i].show();

    if (fireworks[i].done()) {
      fireworks.splice(i, 1);
    }
  }
}

function windowResized(p5) {
  p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
}

function sketch(p5) {
  p5.setup = () => setup(p5);
  p5.draw = () => draw(p5);
  p5.windowResized = () => windowResized(p5)
}

function Accept() {
  fetch("https://prod-33.eastus.logic.azure.com/workflows/e88a40beb7e844e5b714b1b195d4e7cf/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Rv8u18Y9NPrtAwFITA-lyGIGaq9cQkZOExdFaRGqcC4")
  return <ReactP5Wrapper sketch={sketch} />;
}

export default Accept;
