import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './timer.css';


function Timer() {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const navigate = useNavigate();
    const deadline = "July, 9, 2024";

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        if(time < 0){
            navigate("/anniversary");
        }
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="app">
            <div className="container">
                <h1 className="header" style={{fontFamily: "Great Vibes", fontSize: "500%"}}>
                    One Year Anniversary In...
                </h1>
                <div className="timer" role="timer">
                    <div className="col-4">
                        <div className="box-timer">
                            <p id="day">{days < 10 ? "0" + days : days}</p>
                            <span className="text">Days</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box-timer">
                            <p id="hour">{hours < 10 ? "0" + hours : hours}</p>
                            <span className="text">Hours</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box-timer">
                            <p id="minute">{minutes < 10 ? "0" + minutes : minutes}</p>
                            <span className="text">Minutes</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box-timer">
                            <p id="second">{seconds < 10 ? "0" + seconds : seconds}</p>
                            <span className="text">Seconds</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Timer;
