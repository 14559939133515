import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Anniversary() {
    const navigate = useNavigate();
    useEffect(() => { 
        setTimeout(() => navigate("/propose"), 5000)
    }, []);
    return (<div style={{ justifyContent: "center", alignItems: "center", display: "flex", height: '90vh' }}>
        <img src="https://media.tenor.com/H3cqMAsF7VYAAAAi/happy-anniversary.gif" />
    </div>);
}

export default Anniversary;
