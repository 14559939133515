import './App.css';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import headerimg from "./marryme2.3.nobg.png";
import smile1 from "./photos/_H7A0033.JPG";
import smile2 from "./photos/IMG_0358.PNG";

import jam1 from "./photos/jam1.JPEG";
import jam2 from "./photos/jam2.JPEG";

import flowers1 from "./photos/flowers1.JPEG";
import flowers2 from "./photos/flowers2.JPEG";

import cigar1 from "./photos/cigar1.JPEG";
import cigar2 from "./photos/cigar2.JPEG";

import visit1 from "./photos/visit1.JPEG";
import visit2 from "./photos/visit2.JPEG";

import movie1 from "./photos/movie1.JPEG";
import movie2 from "./photos/movie2.JPEG"

import couple1 from "./photos/couple1.JPEG";
import couple2 from "./photos/couple2.JPEG";

import anniv1 from "./photos/IMG_0299.JPEG";
import anniv2 from "./photos/IMG_0305.JPEG";

import goal1 from "./photos/acceptance.png";
import goal2 from "./photos/visa_orig.jpg"

import cute1 from "./photos/cute1.PNG";
import cute2 from "./photos/cute2.PNG";
import cute3 from "./photos/cute3.PNG";
import cute4 from "./photos/cute4.PNG";
import cute5 from "./photos/cute5.png";
import cute6 from "./photos/cute6.MP4";


function App() {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="App">
      <Container style={{ textAlign: 'left' }}>
        <Row>
          <img src={headerimg} width="100%" />
        </Row>
        <Row>
          <Col>
            <div className="box">
              <br /><br />
              <h1 style={{ fontFamily: "Great Vibes", color: "#BD1C1A", fontSize: "300%", fontWeight: "normal" }}> Why I want to marry you?</h1>
              <div>
                Your smile....the same smile you're embarrassed to show is something that always make my heart flutter.
                I was first exposed to it during the Nikah ceremony when I finally got a glimpse of the babes who chose to be my wife, and then a lot more glimpses of that over them video call.
                <br />
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={smile1} height={175} style={{ paddingRight: 30 }} /> <img src={smile2} height={175} />
                </div>
                <br />
                It is the one thing I always want to protect. Your smile has the power to brighten my day and make me forget about all my troubles. In your words...
                <br />
                <p> <i>
                  Teri muskaan ki baat hi kuch aur hai, Jise dikhane mein tujhe sharm aati hai. Dil ko chhoo jaati hai, har baar, Teri hansi ki wo pyaari adaayein.
                </i> </p>
                <br />

                And right after nikaah, we had our first anniversary dinner, I was super nervous during that time, and even moreso because I was sick.
                I wanted to make the day a lot more special and enjoyable, but still those are some very memorable days for me.
                <br />
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={anniv1} height={175} style={{ paddingRight: 30 }} /> <img src={anniv2} height={175} />
                </div>
                <br />
                Infact... it's the first time I clicked a candid pic of you that I still love to look at and keep going back to, even to this day.
                <br />
                <br />

                The next time I got to see you was even more memorable for me. Going to the arcade with you was amazing.
                <br />
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={visit1} height={200} style={{ paddingRight: 30 }} /> <img src={visit2} height={200} />
                </div>
                <br />
                Watching you take an interest in things that I like felt really good.
                But what was even better was just seeing you enjoy yourself and you try your best at baseketball.


                And then obviously, your first movie.
                <br />
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={movie1} height={200} style={{ paddingRight: 30 }} /> <img src={movie2} height={200} />
                </div>
                <br />
                It was soooo nice to sit next to you, hold your hand or rest my head against your shoulder.
                Everytime I go to watch a movie alone, I think of how nice it would be go again with you.
                And then finally, the couple pics.
                <br />
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={couple1} height={200} style={{ paddingRight: 30 }} /> <img src={couple2} height={200} />
                </div>
                <br />
                These pics never fail to put a smile on my face...These are a portion of the events that made me fall in love with you.

                <br />
                <br />
                You really really really care about me. When you asked me to make a bread jam sandwich
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={jam1} height={200} style={{ paddingRight: 30 }} /> <img src={jam2} height={200} />
                </div>
                or when you asked me to get them nuts and eat regulalry, maybe back then I didn't realize how much you cared for me, but today I wholeheartedly do.
                Who wouldn't want to marry someone who cares about them soooo much.
                <br />
                <br />
                And obviously the inside jokes
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={cigar1} height={200} style={{ paddingRight: 30 }} /> <img src={cigar2} height={200} />
                </div>
                You know you're a true couple when you got a fair amount of inside jokes...
                <br />
                <br />

                Flowers...You're the first person to ever give me Flowers
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={flowers1} height={200} style={{ paddingRight: 30 }} /> <img src={flowers2} height={200} />
                </div>
                And I think what makes them even more special is that you didn't send them on a special occasion.
                It's a bit sad to see them wither, but they'll always be there in my heart.

                <br />
                <br />


                They say that shared goals are important for marriage. Not only did we have one.
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <img src={goal1} height={170} style={{ paddingRight: 30 }} /> <img src={goal2} height={170} />
                </div>
                But we crushed it!!!  Each of the acceptance emails we got made me super happy. It made me feel like as a team we can really accomplish something that I though was close to impossible.
                Getting you accepted in a good college and having you in the US always felt like a dream, but I'm super proud of ourselves for actually accomplishing that dream.
                Right now the coffee shop may sound like a far fetched dream, but with you, I'm confident it will happen.
                <br />
                <br />

                And lastly your patience with me. That truly is one of the pillars of this relationship. That alone is a reason strong enought to marry you.
                And it's not just your patience waiting for me to change, but your patience with my "nakhre" as well.
                <br />
                <br />
                Considering today is our anniversay, I also wanted this section to be a bit of a recap over our some key parts and moments of our relationship, and also to convey why I love you!!
              </div>
              <br /><br />
              <h1 style={{ fontFamily: "Great Vibes", color: "#BD1C1A", fontSize: "300%", fontWeight: "normal" }}> Why you may consider marrying me</h1>
              Here are some reasons which might convince you to accept my proposal:
              <ul>
                <li>I try to be understanding and compassionate and will do my best to be the best possible partner to you and someone you can be proud of</li>
                <li>I will give you all the laad and pyaaar in the world</li>
                <li>I will create an environment where you can be "bindass" and do "nakhre" as well</li>
                <li>I will do my best to put my emotions aside to console you and make you feel normal</li>
                <li>I will do my best to make you feel important even if I'm mad at you</li>
                <li>I will use all the sweet sweet words when addressing you</li>
                <li>I will notice the little little things about you and compliment you and care about you</li>
                <li>I will go on long long walks and do trips to the beach with you</li>
                <li>I will dry your hair after you take a shower...or after dancing in the rain </li>
                <li>I will massage your head if you're having a headache.... or even if you're not having a headache...</li>
                <li>When you're on your periods, I'll make the chai for you and massage your back</li>
                <li>I will attend classes with you and help you out with your studies</li>
                <li>I’ve lived apart from my family and I may be too independent, but I'll do my best to rely on you</li>
                <li>I'll help you fullfill your career goals and push you to achieve more</li>
                <li>I will protect that tender little heart of yours</li>
                <li>But most of all... I will alway love you!</li>
              </ul>
              {/* 
              Put aside emotions
              Use sweets words
              GIve her all the laad and pyaar
              What she wants: Help her achieve her goals, career, loose weight, use sweet sweet words, notice little little things
              Second point is bad, Afreen is my partner she should be able to burden me. If she don't handle my emotions, whose else emotions will she handle? 
              Should also comment on her stories and posts
              Need to add more points
              */}
              <br />
              <br />
              And so... my little one, my babes
              <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                <img src={cute1} height={300} style={{ paddingRight: 30 }} /> <img src={cute2} height={300} />
              </div>
              my love, meri jaan
              <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                <img src={cute3} height={300} style={{ paddingRight: 30 }} /> <img src={cute4} height={300} />
              </div>
              my cute cute wife
              <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                <img src={cute5} height={300} style={{ paddingRight: 30 }} /> <video autoPlay loop muted height={300}><source src={cute6} type="video/mp4" /></video>
              </div>
              Will you marry me?
              <br /> <br />
              <div className="d-grid gap-2">
                <Button variant="primary" onClick={handleShow} size="lg">Yes, I will!</Button>
              </div>
              <br /><br />

            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <div className="modal show" style={{ display: 'block', position: 'initial' }} >
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Once you accept there's no turning back. Think carefully....</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              On second thought....
            </Button>
            <Button variant="primary" onClick={() => navigate("/accept")}>
              Yes I am!!!
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default App;
